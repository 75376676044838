.ApprovalFlowTracker {
 /*  &__step {
    // background-color: blue;
    :global(.MuiStepLabel-label) {
      font-size: 12px;
      color: var(--navy-700);
      font-family: var(--font-normal);
      white-space: pre-wrap;
      // line-height: 8px;
    }
    :global(.MuiStepConnector-root) {
      height: 4px;
      background-color: #cccdd6;
      border-radius: 8px;
      top: 6px;
      left: calc(-50% + 14px);
      right: calc(50% + 14px);
      &:global(.Mui-active) {
        background-color: var(--green-700);
      }
    }
    :global(.MuiStepConnector-line) {
      display: none;
    }
  } */

  &__icon {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    color: var(--green-700);
    :global(.MuiSvgIcon-root) {
      width: 12px;
      height: 12px;
      color: white;
      border-radius: 100%;
    }
  }
}
